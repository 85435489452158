body{
  font-size: 16px;
  font-family:$font-primary;
  min-height: 100vh;
}
h2{
  font-family:$font-primary;
  font-weight:$font-w-regular;
  font-size: $font-s-xlarge;
}
.btn{
  border-radius: 5rem;
  &.btn-lg{
    border-radius: 5rem;
    font-weight: $font-w-medium;
  }
  &.square-btn{
    border-radius: 0.3rem !important; /////// rendi il bottone quadrato 
  }
  &.btn-primary{
    background-color: $third;
    border-color: $third;
    &:focus, &.focus, &:hover, &.active, &:active, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active{
      background-color: $third;
      border-color: $third;
    }
    &:focus, &.focus, &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus{
      box-shadow: 0 0 0 0.2rem rgba(110, 170, 125, 0.5);
    }
  }
  &.btn-danger{
    background-color: $second;
    border-color: $second;
    &:focus, &.focus, &:hover, &.active, &:active, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active{
      background-color: $second;
      border-color: $second;
    }
    &:focus, &.focus, &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus{
      box-shadow: 0 0 0 0.2rem rgba(196, 26, 38, 0.5);
    }
  }
  &.btn-info{
    background-color: $forth;
    border-color: $forth;
    &:focus, &.focus, &:hover, &.active, &:active, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active{
      background-color: $forth;
      border-color: $forth;
    }
    &:focus, &.focus, &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus{
      box-shadow: 0 0 0 0.2rem rgba(159, 206, 212, 0.5);
    }
  }
  &.btn-outline-info{
    color: $third;
    border-color: $forth;
    &:focus, &.focus, &:hover, &.active, &:active, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active{
      color: $light-primary;
      background-color: $forth;
      border-color: $forth;
    }
    &:focus, &.focus, &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus{
      box-shadow: 0 0 0 0.2rem rgba(159, 206, 212, 0.5);
    }
  }
}

.padding-box{
  padding-bottom:60px;
  padding-top:60px;
}


#box4{
  background: url('/img/bg-mac-4.png') no-repeat top left;
  padding:100px 0 150px 0;
  @extend .padding-box;
  .Partners{
    img{
      max-width:159px;
      @extend .img-fluid
    }
  }
  .Bannercontact{
    background: url('/img/contact.png') no-repeat top left;
    color:$light-primary;
    a{
      color:$light-primary;
    }
  }
}
.Startups{
  background:$forth-lighten;
  @extend .padding-box;
  .slick-prev::before, .slick-next::before{
    color: #c4c4c4;
    font-size:30px;
  }
  .carousel-item{
    @extend .px-5;
    strong{
      display: block;
    }
    .name{
      font-size:$font-s-small;
    }
    .container-image{
      @extend .d-flex;
      @extend .justify-content-center;
      .logo{
        background:$light-primary;
        width: 120px;
        height:120px;
        text-align: center;
        @extend .rounded-circle;
        @extend .d-flex;
        @extend .align-self-center;
        @extend .px-3;
        img{
          width:100%;
          @extend .align-self-center;
          @extend .img-fluid;
        }
      }
    }
    
    p{
      @extend .pt-3;
      @extend .mb-0;
    }
  }
}