.Footer{
  background: $first;
  padding-bottom:156px;
  margin-top:100px;
  z-index:200;
  color:$light-primary;
  font-size: $font-s-small;
  @include media-breakpoint-up(md) {
    .dg svg{
      width:50%
    }
  }
  .copyright{
    color:$gray-600;
  }
  .link{
    .btn-link{
      font-size: $font-s-small;
      color:$light-primary;
      padding-left:20px;
    }
  }
  .magicw, .magicw-i{
    fill:$light-primary;
  }
}
.buttonTop{
  position: fixed;
  bottom: 3%;
  right:2%;
  font-size:0;
  background-image: url("/img/button-to-top.svg");
  z-index:300;
  width:60px;
  height:60px;
}