// font family
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$font-primary: 'Poppins', sans-serif;

//font weight size
$font-w-light:300;
$font-w-regular:400;
$font-w-medium:500;
$font-w-semibold:600;
$font-w-bold:700;
$font-w-xbold:800;
$font-w-black:900;

//font size
$font-s-xlarge:2.25rem;
$font-s-large:1.5rem;
$font-s-medium:1.125rem;
$font-s-small:0.875rem;


//colors
$primary: #000000;
$light-primary: #ffffff;
$first: #002232; // blue
$second: #C41A26; //red
$third: #6EAA7D; //green
$forth: #9FCED4; //azure
$forth-lighten: lighten(desaturate(adjust-hue($forth, -7), 0.63), 20.98); //#E9F5F5 light azure
$forth-darker: darken(desaturate(adjust-hue($forth, 1), 7.74), 12.75); //#7AB0B8





// da spostare in layout
.general-tit-box{
    color: $forth-darker;
    font-weight: $font-w-bold;
    @extend .pb-2;
}
.head-box{
    font-size: $font-s-xlarge;
}
// da spostare in layout