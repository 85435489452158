.intro{
    padding-top: 60px;
    padding-bottom: 60px;
    background-image: url(/img/bg-fascia-1.png);
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
    .magicw{
      fill:$second;
    }
    .magicw-i{
      fill:$first;
    }
    .investments{
        @extend .pt-2;
        .values{
            @extend .py-4;
            .value{
                color: $second; 
                font-size: 4rem;
                @include media-breakpoint-down(md) {
                    font-size: 3rem;
                }
            }
        }
    }
}
.categories-section{
    padding-top: 60px;
    padding-bottom: 60px;
    background-image: url(/img/bg-fascia-2.png);
    background-repeat: no-repeat;
    background-position: right top;
    overflow: hidden;
    .tit-box{
        font-size: 3rem;
        line-height: 1.2;
        @extend .pb-3;
        @include media-breakpoint-down(md) {
            font-size: 2.5rem;
        }
        strong{
            font-weight: $font-w-semibold;
            color: $third;
        }
    }
    .btn-section{
        @extend .px-2;
        .btn{
            @extend .m-1;
        }
    }
    @include media-breakpoint-down(md) {
        .image-section{
            max-width: 100%;
        }
        
    }
}

.greenbanner{
    @extend .my-4;
    .content-box{
        padding-top: 60px;
        padding-bottom: 60px;
        background-image: url(/img/bg-fascia-3.png);
        background-repeat: no-repeat;
        background-position: left top;
        color: $light-primary;
        background-size: cover;
        @extend .p-5;
        @media  (-webkit-min-device-pixel-ratio: 2) {
            background-image: url(/img/bg-fascia-3x2.png);
        }
        @media  (-webkit-min-device-pixel-ratio: 3) {
            background-image: url(/img/bg-fascia-3x3.png);
        }
        .tit-box{
            font-size: $font-s-large;
            @extend .pb-3;
            line-height: 1.2;
        }
    }
    
}

.timeline{
    padding-top: 60px;
    padding-bottom: 60px;
    .event{
        @extend .pb-5;
        .calendar{
            .row{
                @extend .align-items-center;
                .data{
                    @extend .px-3;
                    color: $forth;
                    font-size: $font-s-medium;
                }
                .dash{
                    border: 2px solid $forth;
                    flex: 1;
                    height: 2px;
                    border-radius: 10px;
                }
            }
        }
        .tit{
            font-size: $font-s-large;
            color: $second;
        }
    }
    
}

.focus{
    padding-top: 60px;
    padding-bottom: 60px;
    background-image: url(/img/bg-fascia-5.png);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    @media  (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(/img/bg-fascia-5x2.png);
    }
    @media  (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(/img/bg-fascia-5x3.png);
    }
    .area{
        @extend .d-flex;
        @extend .flex-column;
        line-height: 1.2;
        .tit-box{
            font-size: $font-s-large;
            font-weight: $font-w-medium;
            text-transform: uppercase;
            @extend .pb-2;
            @include media-breakpoint-down(md) {
                font-size: 1rem;
                font-weight: $font-w-semibold;
            }
        }
        .box{
            @extend .flex-fill;
            background-color: $light-primary;
            @extend .p-3;
            -webkit-box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.1);
            @extend .mb-4;
            ul{
                list-style: none;
                @extend .p-0;
                li{
                    @extend .mb-2;
                    @extend .pb-2;
                    border-bottom: 1px solid #E5E5E5;
                    color: $third;
                    font-weight: $font-w-semibold;
                    @include media-breakpoint-down(md) {
                        font-size: 0.8rem;
                        word-break: break-word;
                    }
                    @include media-breakpoint-down(sm) {
                        font-size: 1rem;
                        word-break: inherit;
                    }
                    p{
                        color: $primary;
                        font-weight: $font-w-regular;
                        @extend .mb-0;
                    }
                    &:last-child {
                        border-bottom: 0px;;
                    }
                }
            }
        }
    }
}

.whyjoin{
    padding-top: 60px;
    padding-bottom: 60px;
    .why-box{
        @extend .pb-3;
        .tit-box, p{
            @extend .pl-4;
        }
        .tit-box{
            color: $third;
            font-size: $font-s-medium;
            font-weight: $font-w-bold;
            background-image: url(/img/bullet-p.png);
            background-repeat: no-repeat;
            background-position: left center;
        }
    }
}