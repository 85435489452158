@import "~bootstrap/scss/bootstrap";

@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

@import "variables";
@import "header";
@import "footer";
@import "layout";
@import "home";

