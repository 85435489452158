.header{
    background-color: $first;
    color: $light-primary;
    @extend .py-3;
    .bg-dark{
        background-color: $first !important;
    }
    .navbar-dark{
        @extend .pt-3;
        .navbar-nav{
            .nav-link{
                color: rgba(255, 255, 255, 1);
            }
        }
        .navbar-brand{
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }
    }
    .text-lazio, .text-fesr, .text-eu{
        fill: $light-primary;
    }
    .fers-logo{
        padding-left: 3px;
    }
    .logo-section{
        @extend .pb-3;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        background-color: $first !important;
        z-index: 2;
        .logo-small{
            display: none;
        }
    }
    &.smaller{
        .logo-section{
            .logo-small{
                display: block;
            }
        }
    }
}